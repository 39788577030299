export const QUERY_KEYS = {
  TEXTFILTERS: 'textFilters',
  USERS: 'users',
  USER: 'user',
  NOTES: 'notes',
  MOMENTS: 'moments',
  CAMPAIGNS: 'campaigns',
  CAMPAIGN: 'campaign',
  CAMPAIGN_REWARDS: 'campaignRewards',
  DIALOGS: 'dialogs',
  DIALOGS_DETAILS: 'dialogDetails',
  DIALOG_MEMBER: 'dialogMembers',
  EXPLORE: 'explore',
  FEATURED: 'featured',
} as const
