import React from 'react'
import {
  Avatar, Card, CardContent, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import { User } from '../user/types'
import { DateText } from '../../app/Timezone'
import AmbassadorUserScore from './AmbassadorUserScore'

type DetailRowProps = {
  label: string
  value: React.ReactNode
}

function DetailRow({ label, value }: DetailRowProps) {
  return (
    <TableRow>
      <TableCell sx={{
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        paddingRight: '150px',
      }}
      >
        {label}
      </TableCell>
      <TableCell sx={{
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        width: '100%',
      }}
      >
        {value}
      </TableCell>
    </TableRow>
  )
}

export function UserDetailsCard({ user }: { user: User }) {
  return (
    <>
      <Stack direction="row">
        <Avatar
          src={user.customData?.avatarUrl}
          sx={{
            width: { xs: 60, sm: 80, md: 100 },
            height: { xs: 60, sm: 80, md: 100 },
            m: { xs: 1, sm: 2 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            }}
          >
            {user.userName ? user.userName.charAt(0) : '?'}
          </Typography>
        </Avatar>

        <Stack direction="column" justifyContent="center">
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              m: 0,
            }}
          >
            {user.fullName}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: 'text.secondary',
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
            }}
          >
            {`@${user.userName}`}
          </Typography>
        </Stack>
      </Stack>
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <DetailRow label="Quickblox User ID" value={user.quickbloxUserId} />
                <DetailRow label="Phone" value={user.phone} />
                <DetailRow label="Accumulated Score" value={user.totalScore} />
                <DetailRow label="Created At" value={<DateText date={user.createdAt} />} />
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <br />
      <Grid>
        <AmbassadorUserScore quickbloxUserId={user.quickbloxUserId} />
      </Grid>
      <br />
    </>
  )
}
