import { useEffect, useState } from 'react'
import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table'
import { DateCell } from '../../app/Timezone'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import { styleObj } from '../../../styles/rowStyle'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { DIALOG_TYPES, EVENT, REGION_TYPE } from '../../../constants'
import { useGetFeatured } from '../../../api/query/dialogs/dialogs'
import CustomTypeFilter from '../common/dialogs/CustomTypeFilter'
import { DialogGrid } from './types'
import { useFeaturedTableSearchParams } from './hooks/useFeaturedTableSearchParams'
import CustomRegionFilter from '../common/dialogs/CustomRegionFilter'

function Featured() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
    showRegionSelect,
    setShowRegionSelect,
  } = useFeaturedTableSearchParams()

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetFeatured(queryParams)

  const [regionSelect, setRegionSelect] = useState(REGION_TYPE[0])

  useEffect(() => {
    refetch()
  }, [queryParams, refetch])

  useEffect(() => {
    if (showRegionSelect) {
      setRegionSelect(showRegionSelect)
    }
  }, [showRegionSelect])

  useEffect(() => {
    if (setShowRegionSelect) {
      setShowRegionSelect(regionSelect)
    }
  }, [regionSelect, setShowRegionSelect])

  const columns: MrtColumnDef<DialogGrid>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 250,
    },
    {
      accessorKey: 'quickbloxDialogId',
      header: 'Quickblox Dialog ID',
      size: 230,
      Cell: ({ row }) => row.original.id,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 150,
      enableSorting: false,
      Cell: ({ row }) => DIALOG_TYPES[row.original.type as keyof typeof DIALOG_TYPES] || null,
      Filter: () => CustomTypeFilter({
        field: 'type',
        filter: {
          filterOp: 'equals',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 150,
      Cell: ({ row }) => row.original.region,
      Filter: () => CustomRegionFilter({
        setRegionSelect: (value: string | undefined) => setRegionSelect(value || ''),
        regionSelect,
      }),
      filterFn: 'equals',
      enableSorting: false,
    },
    {
      accessorKey: 'count_participant',
      header: 'Participants',
      size: 150,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter, setFilter }),
    },
  ]

  const customStyle = (row: MrtRow<DialogGrid>) => ({
    ...(row.original.deletedAt ? styleObj.error : {}),
  })

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      defaultPath="/dialogs/featured"
      showExport
    />
  )
}

export default Featured
