import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { useBaseTableSearchParams } from '../../hooks/useBaseTableSearchParams'
import { ExtendedKpiQueryParams } from '../../../../api/query/params';

export function useUserKpiTableSearchParams(defaultInit?: URLSearchParamsInit) {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    searchValue, searchField, searchOp, pageSize, filterValue, from, to, timezone, storeCountryCode, ...filteredParams
  } = Object.fromEntries(searchParams.entries())
  const baseTableParams = useBaseTableSearchParams({
    defaultInit,
    extraParams: {
      from,
      to,
      timezone,
      storeCountryCode,
    },
  })

  return {
    ...baseTableParams,
    queryParams: {
      ...baseTableParams.queryParams,
      from,
      to,
      timezone,
      storeCountryCode,
    } as ExtendedKpiQueryParams,
    clearFilters: () => {
      baseTableParams.clearFilters()
      setSearchParams(() => {
        const newParams = new URLSearchParams();
        if (from) newParams.set('from', from);
        if (to) newParams.set('to', to);
        if (storeCountryCode) newParams.set('storeCountryCode', storeCountryCode);
        if (timezone) newParams.set('timezone', timezone);
        return newParams;
      });
    },
  }
}
