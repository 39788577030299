import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material';

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: (value?: string) => void
  onCancel: () => void;
  form?: boolean;
  content?: React.ReactNode
}

function ConfirmationModal({
  open,
  title,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
  form = false,
  content,
}: ConfirmationModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      PaperProps={{
        component: form ? 'form' : 'div',
        onSubmit: form
          ? (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const input = formData.get('input') as string;
            if (onConfirm) {
              onConfirm(input);
            }
          }
          : undefined,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button onClick={onCancel} color="error">
          {cancelLabel}
        </Button>
        <Button type={form ? 'submit' : 'button'} onClick={form ? undefined : () => onConfirm()} color="success">
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
