import { useEffect, useState } from 'react'
import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table'
import {
  FormControl, Grid, InputLabel, MenuItem, Select,
} from '@mui/material'
import { DateCell } from '../../app/Timezone'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import { styleObj } from '../../../styles/rowStyle'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { DIALOG_TYPES, EXPLORE_TYPE, REGION_TYPE } from '../../../constants'
import { useGetExplore } from '../../../api/query/dialogs/dialogs'
import CustomTypeFilter from '../common/dialogs/CustomTypeFilter'
import { DialogGrid } from './types'
import { useExploreTableSearchParams } from './hooks/useExploreTableSearchParams'
import CustomRegionFilter from '../common/dialogs/CustomRegionFilter'

function Explore() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
    showExploreSelect,
    setShowExploreSelect,
    showRegionSelect,
    setShowRegionSelect,
  } = useExploreTableSearchParams()

  const [exploreSelect, setExploreSelect] = useState(EXPLORE_TYPE[0])
  const [regionSelect, setRegionSelect] = useState(REGION_TYPE[0])

  const columns: MrtColumnDef<DialogGrid>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 250,
      enableSorting: false,
    },
    {
      accessorKey: 'quickbloxDialogId',
      header: 'Quickblox Dialog ID',
      size: 230,
      Cell: ({ row }) => row.original.id,
      enableSorting: false,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 150,
      enableSorting: false,
      Cell: ({ row }) => DIALOG_TYPES[row.original.type as keyof typeof DIALOG_TYPES] || null,
      Filter: () => CustomTypeFilter({
        field: 'type',
        filter: {
          filterOp: 'equals',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
    },
    {
      accessorKey: 'count_participant',
      header: 'Participants',
      size: 150,
      enableSorting: true,
      Cell: ({ row }) => row.original.count_participant,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 150,
      Cell: ({ row }) => row.original.region,
      Filter: () => CustomRegionFilter({
        setRegionSelect: (value: string | undefined) => setRegionSelect(value || ''),
        regionSelect,
      }),
      filterFn: 'equals',
      enableSorting: false,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
      enableSorting: false,
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter, setFilter }),
      enableSorting: false,
    },
    {
      accessorKey: 'deletedAt',
      header: 'Deleted At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'deletedAt', filter, setFilter }),
      enableSorting: false,
    },
  ]

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetExplore(queryParams)

  useEffect(() => {
    refetch()
  }, [queryParams, refetch])

  useEffect(() => {
    if (showExploreSelect) {
      setExploreSelect(showExploreSelect)
    }
    if (showRegionSelect) {
      setRegionSelect(showRegionSelect)
    }
  }, [showExploreSelect, showRegionSelect])

  useEffect(() => {
    if (setShowExploreSelect) {
      setShowExploreSelect(exploreSelect)
    }
    if (setShowRegionSelect) {
      setShowRegionSelect(regionSelect)
    }
  }, [exploreSelect, regionSelect, setShowExploreSelect, setShowRegionSelect])

  const customStyle = (row: MrtRow<DialogGrid>) => ({
    ...(row.original.deletedAt ? styleObj.error : {}),
  })

  const renderSelectExplore = () => (
    <Grid container columnSpacing={1} px={1}>
      <Grid item>
        <FormControl sx={{ minWidth: 100 }} size="small">
          <InputLabel id="explore-select-label">explore</InputLabel>
          <Select
            labelId="explore-select-label"
            label="Explore"
            autoWidth
            value={exploreSelect}
            onChange={(event) => setExploreSelect(event.target.value)}
          >
            {
              EXPLORE_TYPE.map((value) => (
                <MenuItem key={value} value={value}>{value}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={(newFilters) => {
        const updatedFilters = (typeof newFilters === 'function' ? newFilters([]) : newFilters).filter(
          (colFilter) => colFilter.id !== 'region',
        )
        setColumnFilters(updatedFilters)
      }}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      defaultPath="/dialogs/explore"
      renderTopToolbarCustomContent={renderSelectExplore}
      showExport
    />
  )
}

export default Explore
