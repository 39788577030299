import React from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useGetSingleUser } from '../../../api/query/users/users'
import { UserDetailsCard } from './AmbassadorUserDetailsCard'
import { User } from '../user/types'

function AmbassadorUserDetail() {
  const { quickbloxUserId } = useParams()

  const userId = parseInt(quickbloxUserId as string)

  const { data, isLoading, isError } = useGetSingleUser(userId)

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return <div>Error fetching user</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  const userData: User = {
    ...data.user,
  }

  return <UserDetailsCard user={userData} />
}

export default AmbassadorUserDetail
