import {
  type MRT_ColumnDef as MrtColumnDef,
} from 'material-react-table'
import { DateCell } from '../../app/Timezone'
import { useGetUserAmbassadorScore } from '../../../api/query/users/users'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { useBaseTableSearchParams } from '../hooks/useBaseTableSearchParams'

const headerName = 'Ambassador Score'
function AmbassadorUserScore({ quickbloxUserId }: { quickbloxUserId: number }) {
  const element = document.querySelector('.mainPageTitle')
  if (element) {
    element.textContent = headerName
  }
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  } = useBaseTableSearchParams()

  const columns: MrtColumnDef<any>[] = [
    {
      accessorKey: 'action',
      header: 'Action',
      size: 200,
    },
    {
      accessorKey: 'score',
      header: 'Score',
      size: 150,
    },
    {
      accessorKey: 'detail',
      header: 'Detail',
      size: 800,
      enableSorting: false,
      Cell: ({ row }) => JSON.stringify(row.original.detail, null, 2) || 'N/A',
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 250,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
  ]

  if (queryParams.sortField === '') {
    queryParams.sortField = 'createdAt'
    queryParams.dir = 'desc'
  }

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetUserAmbassadorScore(quickbloxUserId, queryParams)

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={() => ({ cursor: 'cursor' })}
    />
  )
}

export default AmbassadorUserScore
