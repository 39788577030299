import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { DateCell } from '../app/Timezone'
import CustomDateFilter from '../v2/common/date/CustomDateFilter'
import CustomStatusFilter from '../v2/common/users/CustomStatusFilter'
import { useGetDailyActiveUsers } from '../../api/query/users/users';
import { styleObj } from '../../styles/rowStyle';
import MrtBaseDataGrid from '../v2/common/MrtBaseDataGrid';
import { useUserKpiTableSearchParams } from '../v2/kpi/hooks/useUserKPITableSearchParam';
import { User } from '../v2/user/types';
import { getUserStatus } from '../v2/common/users/getUserStatus';

function DauDetail() {
  const navigate = useNavigate();

  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  } = useUserKpiTableSearchParams();

  const columns: MrtColumnDef<User>[] = [
    {
      accessorKey: 'userStatus',
      header: 'Status',
      size: 90,
      Cell: ({ row }) => getUserStatus(row.original),
      Filter: () => CustomStatusFilter({
        field: 'userStatus',
        filter: {
          filterOp: '=',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
      enableSorting: false,
    },
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: 120,
    },
    {
      accessorKey: 'fullName',
      header: 'Fullname',
      size: 150,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 130,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 120,
    },
    {
      accessorKey: 'reporter',
      header: 'Reports Made',
      size: 160,
    },
    {
      accessorKey: 'reported',
      header: 'Reported By Others',
      size: 200,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
  ];

  const {
    data, isError, isLoading, refetch,
  } = useGetDailyActiveUsers(queryParams);

  const customStyle = (row: MrtRow<User>) => (row.original.deletedAt ? styleObj.error : {});

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      onRowDoubleClick={(row) => navigate(`/users/${row.original.quickbloxUserId}`)}
    />
  );
}

export default DauDetail
