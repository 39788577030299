import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
  FilterAltOff as FilterAltOffIcon, Refresh as RefreshIcon, Rowing, SaveAlt as SaveAltIcon,
} from '@mui/icons-material';
import {
  Box, Button, IconButton, Tooltip,
} from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_ColumnFiltersState as MrtColumnFiltersState,
  type MRT_PaginationState as MrtPaginationState,
  type MRT_Row as MrtRow,
  type MRT_SortingState as MrtSortingState,
} from 'material-react-table';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/common.css';
import moment from 'moment'
import { DateTime } from 'luxon';
import { TimezoneContext } from '../../app/Timezone';
import { DIALOG_TYPES, USERSTATUS } from '../../../constants';
import { getUserStatusText } from './users/getUserStatus';
import { User } from '../user/types';

interface MrtBaseDataGridProps<T extends Record<string, any>> {
  columns: MrtColumnDef<T>[];
  data: T[];
  totalRows: number;
  isLoading?: boolean;
  isError?: boolean;
  onRefetch?: () => void;
  onClearFilters?: () => void;
  onColumnFiltersChange: React.Dispatch<React.SetStateAction<MrtColumnFiltersState>>;
  setPagination: React.Dispatch<React.SetStateAction<MrtPaginationState>>;
  pagination: MrtPaginationState;
  setSorting: React.Dispatch<React.SetStateAction<MrtSortingState>>;
  sorting: MrtSortingState;
  initialState?: {
    showColumnFilters?: boolean;
    density?: 'compact' | 'comfortable' | 'spacious';
  };
  manualFiltering?: boolean;
  manualSorting?: boolean;
  customRowStyle?: (row: MrtRow<T>) => Record<string, any>;
  onRowDoubleClick?: (row: MrtRow<T>) => void
  defaultPath?: string;
  renderTopToolbarCustomContent?: () => ReactJSXElement;
  renderBottomToolbarCustomContent?: () => ReactJSXElement;
  manualPagination?: boolean
  showExport?: boolean
  exportPathName?: string
}

type Column<T> = {
  header?: string
  headerName?: string
  accessorKey?: keyof T
  field?: keyof T
}

function MrtBaseDataGrid<T extends Record<string, any>>({
  columns,
  data,
  totalRows,
  isLoading,
  isError,
  onRefetch,
  onClearFilters,
  onColumnFiltersChange,
  setPagination,
  pagination,
  setSorting,
  sorting,
  initialState,
  manualFiltering = true,
  manualSorting = true,
  customRowStyle,
  onRowDoubleClick,
  defaultPath = '',
  renderTopToolbarCustomContent,
  renderBottomToolbarCustomContent,
  manualPagination = true,
  showExport,
  exportPathName,
}: MrtBaseDataGridProps<T>) {
  const navigate = useNavigate();

  const handleRowDoubleClick = (row: MrtRow<T>) => {
    if (onRowDoubleClick) {
      onRowDoubleClick(row);
    } else if (defaultPath) {
      // since row id is unique and the path exists, we can use it as a path, but change if needed
      navigate(`${defaultPath}/${row.id}`);
    }
  };
  const { timezone } = useContext(TimezoneContext)

  const handleExportData = () => {
    exportToCSV(data, columns, exportPathName ?? defaultPath, timezone)
  }

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: totalRows,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: true,
      density: 'compact',
      ...initialState,
    },
    manualFiltering,
    manualSorting,
    manualPagination,
    enableFacetedValues: true,
    enableColumnResizing: true,
    enableGlobalFilter: false,
    enableMultiSort: false,
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => handleRowDoubleClick(row),
      sx: {
        cursor: 'pointer',
        ...(customRowStyle ? customRowStyle(row) : {}),
      },
    }),
    state: {
      isLoading,
      sorting,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [25, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
    columnFilterDisplayMode: 'subheader',
    onColumnFiltersChange,
    onPaginationChange: setPagination,
    onSortingChange: (updaterOrValue) => {
      const newSorting = typeof updaterOrValue === 'function'
        ? updaterOrValue(sorting)
        : updaterOrValue;
      setSorting(newSorting.slice(0, 1));
    },
    muiTablePaperProps: {
      sx: {
        display: 'flex', flexDirection: 'column',
      },
    },
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={onRefetch}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Clear Filters">
            <IconButton onClick={onClearFilters}>
              <FilterAltOffIcon />
            </IconButton>
          </Tooltip>
          {renderTopToolbarCustomContent?.()}
        </Box>

        {showExport && (
          <Box sx={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#F6F6F6',
                },
              }}
              onClick={handleExportData}
              disabled={data.length === 0}
              startIcon={<SaveAltIcon />}
            >
              Export
            </Button>
          </Box>
        )}
      </Box>
    ),
    renderBottomToolbarCustomActions: () => (
      renderBottomToolbarCustomContent?.()
    ),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        maxWidth: 'fit-content',
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  );
}

export function convertToTimezone(date: string | null, timezone: string) {
  if (!date) return null

  const datetime = DateTime.fromISO(date, { zone: timezone })
  return `\u00A0${datetime.toFormat('yyyy/MM/dd HH:mm:ss')}`
}

export function exportToCSV<T extends Record<string, any>>(data: T[], columns: Column<T>[], path: string, timezone: string) {
  const headers = `${columns.map((col) => col.header || col.headerName).join(',')}\n`
  const csvRows = data.map((row) => columns.map((col) => {
    const key = (col.accessorKey || col.field) as keyof typeof row
    let cellValue = row[key] ?? ''
    if (path.includes('dialogs')) {
      switch (key) {
        case 'quickbloxDialogId': {
          const newKey = 'id' as keyof typeof row
          cellValue = row[newKey] ?? ''
          break
        }
        case 'type': {
          cellValue = DIALOG_TYPES[row[key] as keyof typeof DIALOG_TYPES] || ''
          break
        }
        default:
          break
      }
    } else if (path.includes('moments')) {
      switch (key) {
        case 'contents': {
          cellValue = row[key] ?? ''
          if (typeof cellValue === 'object' && cellValue !== null) {
            cellValue = JSON.stringify(cellValue)
          }
          break
        }
        default:
          break
      }
    } else if (path.includes('users')) {
      switch (key) {
        case 'userStatus': {
          cellValue = getUserStatusText(row as unknown as User)
          break
        }
        default:
          break
      }
    }
    if (key === 'createdAt' || key === 'updatedAt' || key === 'deletedAt') {
      const utcDate = convertToTimezone(row[key], timezone)
      cellValue = utcDate ?? ''
    }
    return `"${String(cellValue).replace(/"/g, '""')}"`
  }).join(',')).join('\n')

  const csvContent = `\uFEFF${headers}${csvRows}`
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = `${path.replace(/\//g, '')}-${moment().format('YYYYMMDD')}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}

export default MrtBaseDataGrid;
