import React from 'react'
import { MaterialReactTable, MRT_ColumnDef as MrtColumnDef } from 'material-react-table'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Dialog } from '../../dialogs/types'

type UserDialogsCardProps = {
  dialogs: Dialog[],
  quickbloxUserId: number
}

export function UserDialogsCard({ dialogs, quickbloxUserId }: UserDialogsCardProps) {
  const navigate = useNavigate()

  const dialogColumns: MrtColumnDef<Dialog>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 200,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 200,
    },
    {
      accessorKey: 'quickbloxDialogId',
      header: 'Dialog ID',
      size: 250,
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        maxWidth: 'fit-content',
      }}
    >

      <MaterialReactTable
        columns={dialogColumns}
        data={dialogs}
        manualSorting={false}
        manualPagination={false}
        manualFiltering={false}
        enableColumnResizing
        muiPaginationProps={{
          rowsPerPageOptions: [25, 50, 100],
        }}
        initialState={{
          pagination: {
            pageIndex: 0,
            pageSize: 25,
          },
          density: 'compact',
        }}
        muiTablePaperProps={{
          sx: {
            display: 'flex', flexDirection: 'column',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => {
            navigate(`/users/${quickbloxUserId}/dialogs/${row.original.quickbloxDialogId}`)
          },
          sx: { cursor: 'pointer' },
        })}
      />
    </Box>
  )
}
