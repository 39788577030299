import { Delete, NavigateBefore } from '@mui/icons-material'
import {
  Box, Button, Card, CardActions, CardContent, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import eventBus from '../../services/eventBus'
import { fetchAdmin } from '../../services/fetch'
import { Tab, TabPanel, Tabs } from '../common/Tabs'
import WidgetTagsAuth, { expectedWidgetDeleteReportTag } from '../common/WidgetTagsAuth'
import MomentDetails from '../moment/MomentDetails'
import UserDetails from '../v2/user/details/UserDetails'
import DeleteMomentReportModal from './DeleteMomentReportModal'
import { MomentReportInfo } from './MomentReports'
import ResolveAllMomentReportsModal from './ResolveAllMomentReportsModal'
import ResolveMomentReportModal from './ResolveMomentReportModal'
import UserProfileLink from '../user/UserProfileLink'

interface Props {
  reportInfo?: MomentReportInfo
}

function MomentReportDetails(props: Props) {
  const { reportInfo } = props
  const { identifier } = useParams()
  const [details, setDetails] = useState<MomentReportInfo>()
  const navigate = useNavigate()

  useEffect(() => {
    if (reportInfo) {
      setDetails(reportInfo)
    } else {
      fetchAdmin({
        path: `moment-reports/${identifier}`,
        method: 'GET',
      })
        .then((res) => res.json())
        .then((json) => setDetails(json))
    }
  }, [reportInfo, identifier])

  function onExit() {
    if (reportInfo) {
      eventBus.dispatch('goBackToMomentReports')
    } else {
      navigate('/reports/moments')
    }
  }

  if (details === undefined) {
    return null
  }

  return (
    <>
      <Button
        startIcon={<NavigateBefore />}
        onClick={onExit}
      >
        Reports
      </Button>

      <Tabs>
        <Tab label="report" />
        <Tab label="moment" />
        <Tab label="user" />
        <Tab label="reporter" />
      </Tabs>

      <TabPanel>
        <ReportDetailsCard
          reportInfo={details}
          onExit={onExit}
        />
      </TabPanel>

      <TabPanel>
        <MomentDetails
          identifier={details.momentIdentifier}
          backText=""
          exitDetails={() => {}}
        />
      </TabPanel>

      <TabPanel>
        <Box sx={{ width: '100%' }}>
          <UserDetails quickbloxUserIdByTab={details.moment.quickbloxUserId} />
        </Box>
      </TabPanel>

      <TabPanel>
        <Box sx={{ width: '100%' }}>
          <UserDetails quickbloxUserIdByTab={details.quickbloxUserId} />
        </Box>
      </TabPanel>
    </>
  )
}

export default MomentReportDetails

interface ReportDetailsCardProps {
  reportInfo: MomentReportInfo
  onExit: () => void
}

function ReportDetailsCard(props: ReportDetailsCardProps) {
  const { reportInfo, onExit } = props
  const createdAt = moment(new Date(reportInfo.createdAt)).format('MMMM Do YYYY, h:mm:ss A')
  const updatedAt = moment(new Date(reportInfo.updatedAt)).format('MMMM Do YYYY, h:mm:ss A')
  const resolvedAt = reportInfo.resolvedAt ? moment(new Date(reportInfo.resolvedAt)).format('MMMM Do YYYY, h:mm:ss A') : null
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false)
  const [showResolveModal, setShowResolveModal] = useState(false)
  const [showResolveAllModal, setShowResolveAllModal] = useState(false)

  return (
    <>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="h3" gutterBottom>
          {`Report #${reportInfo.id}`}
        </Typography>
        <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
          <IconButton
            onClick={() => setShowDeleteReportModal(true)}
          >
            <Delete fontSize="large" />
          </IconButton>
        </WidgetTagsAuth>
      </Stack>

      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Report Message
          </Typography>
          <Typography variant="body2">
            {reportInfo.details ? reportInfo.details : 'No message.'}
          </Typography>
        </CardContent>

        <CardContent>
          <Typography variant="h4" gutterBottom>
            Report Details
          </Typography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Reporter QB User ID
                  </TableCell>
                  <TableCell>
                    <UserProfileLink userId={reportInfo.quickbloxUserId} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Category
                  </TableCell>
                  <TableCell>
                    {reportInfo.category}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Created At
                  </TableCell>
                  <TableCell>
                    {createdAt}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Updated At
                  </TableCell>
                  <TableCell>
                    {updatedAt}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    Resolved At
                  </TableCell>
                  <TableCell>
                    {resolvedAt || 'Not Yet Resolved'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
          <CardActions>
            <Button variant="outlined" color="info" disabled={Boolean(reportInfo.resolvedAt)} onClick={() => setShowResolveModal(true)}>Resolve</Button>
            <Button variant="outlined" color="info" onClick={() => setShowResolveAllModal(true)}>Resolve All</Button>
          </CardActions>
        </WidgetTagsAuth>
      </Card>

      <DeleteMomentReportModal
        reportId={reportInfo.id}
        open={showDeleteReportModal}
        handleClose={(actionTriggered: boolean) => {
          setShowDeleteReportModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ResolveMomentReportModal
        reportId={reportInfo.id}
        open={showResolveModal}
        handleClose={(actionTriggered: boolean) => {
          setShowResolveModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ResolveAllMomentReportsModal
        momentIdentifier={reportInfo.momentIdentifier}
        open={showResolveAllModal}
        handleClose={(actionTriggered: boolean) => {
          setShowResolveAllModal(false)
          if (actionTriggered) {
            onExit()
          }
        }}
      />
    </>
  )
}
