import React, { useEffect } from 'react'
import {
  Box,
  Tab as MuiTab,
  Tabs as MuiTabs,
  SxProps,
  Theme,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export interface TabItem {
  label: string
  content: React.ReactNode
  tabProps?: React.ComponentProps<typeof MuiTab>
  containerProps?: React.ComponentProps<typeof Box>
}

interface TabsProps {
  tabs: TabItem[]
  paramName?: string
  defaultTab?: string
  tabsProps?: React.ComponentProps<typeof MuiTabs>
  tabProps?: React.ComponentProps<typeof MuiTab>
  containerProps?: React.ComponentProps<typeof Box>
}

function Tabs({
  tabs,
  paramName = 'tab',
  defaultTab,
  tabsProps = {},
  tabProps = {},
  containerProps = {},
}: TabsProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultTabValue = defaultTab || tabs[0]?.label
  const currentTabValue = searchParams.get(paramName) || defaultTabValue
  const currentTabIndex = tabs.findIndex(
    (tab) => tab.label === currentTabValue,
  )
  const activeTabIndex = currentTabIndex >= 0 ? currentTabIndex : 0

  const handleChange = (_: React.SyntheticEvent, newIndex: number) => {
    const newTabValue = tabs[newIndex]?.label
    if (newTabValue && newTabValue !== currentTabValue) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set(paramName, newTabValue)
      setSearchParams(newParams, { replace: true })
    }
  }

  useEffect(() => {
    if (!searchParams.has(paramName) && defaultTabValue) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set(paramName, defaultTabValue)
      setSearchParams(newParams, { replace: true })
    }
  }, [paramName, defaultTabValue, searchParams, setSearchParams])

  const tabsSx: SxProps<Theme> = {
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap',
    },
    '& .MuiTab-root': {
      fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
      padding: { xs: '6px 8px', sm: '12px 16px' },
      minWidth: { xs: '90px', sm: '120px', md: '160px' },
      minHeight: { xs: '35px', sm: '48px' },
    },
    '& .MuiTabs-indicator': {
      height: { xs: 2, sm: 3 },
    },
    ...((tabsProps.sx as object) || {}),
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...containerProps}
    >
      <Box>
        <MuiTabs
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="app tabs"
          {...tabsProps}
          sx={tabsSx}
        >
          {tabs.map((tab) => (
            <MuiTab
              key={tab.label}
              label={tab.label}
              id={`tab-${tab.label}`}
              aria-controls={`tabpanel-${tab.label}`}
              {...tabProps}
              {...(tab.tabProps || {})}
            />
          ))}
        </MuiTabs>
      </Box>
      {tabs.map((tab, index) => (
        <Box
          key={tab.label}
          role="tabpanel"
          hidden={activeTabIndex !== index}
          sx={{
            p: 1,
            overflow: 'auto',
          }}
          {...(tab.containerProps || {})}
        >
          {activeTabIndex === index && tab.content}
        </Box>
      ))}
    </Box>
  )
}

export default Tabs
