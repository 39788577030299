import {
  type MRT_ColumnDef as MrtColumnDef,
  MRT_TableInstance as MrtTableInstance,
} from 'material-react-table'
import {
  useMemo, useState,
} from 'react'
import {
  MRT_PaginationState as MrtPaginationState,
  MRT_SortingState as MrtSortingState,
  MRT_ColumnFiltersState as MrtColumnFiltersState,
} from 'material-react-table'
import MrtBaseDataGrid from '../../common/MrtBaseDataGrid'

type Friend = {
  quickbloxUserId: number,
  userName: string,
  quickbloxDialogId: string,
}

export function UserFriendsCard({ friends }: { friends: Friend[] }) {
  const [pagination, setPagination] = useState<MrtPaginationState>({
    pageIndex: 0,
    pageSize: 25,
  })

  const [sorting, setSorting] = useState<MrtSortingState>([])

  const [columnFilters, setColumnFilters] = useState<MrtColumnFiltersState>([])

  const columns: MrtColumnDef<Friend>[] = [
    {
      accessorKey: 'id',
      header: 'User ID',
      size: 200,
      filterVariant: 'text',
    },
    {
      accessorKey: 'userName',
      header: 'User Name',
      size: 200,
    },
    {
      accessorKey: 'quickbloxDialogId',
      header: 'Dialog ID',
      size: 270,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 200,
      filterVariant: 'select',
      filterSelectOptions: ['ACCEPTED', 'REQUESTED', 'REJECTED', 'REMOVED'],
    },
  ]

  const clearFilters = () => {
    setColumnFilters([])
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
  }

  const filteredFriends = useMemo(() => friends.filter((friend) => columnFilters.every(({ id, value }) => {
    if (!value) return true

    const cellValue = friend[id as keyof Friend]
    const cellString = cellValue !== undefined && cellValue !== null ? String(cellValue).toLowerCase() : ''

    return cellString.includes(String(value).toLowerCase())
  })), [friends, columnFilters])

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={filteredFriends}
      totalRows={filteredFriends.length}
      manualSorting={false}
      manualPagination={false}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      sorting={sorting}
      setSorting={setSorting}
      defaultPath="/users"
      showExport
      exportPathName="user-friends"
    />
  )
}
