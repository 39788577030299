/* eslint-disable no-console */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../keys';
import { fetchAdmin } from '../../../services/fetch';
import { QueryParams } from '../params';

export function useGetUsers(params: QueryParams) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'users',
        method: 'GET',
        queries: queryParams,
      });
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Error fetching users:', errorData)
      }
      return response.json();
    },
    keepPreviousData: true,
  });
}

export function useGetAllUserAmbassador(params: QueryParams) {
  const queryParams = {
    ...params,
  }
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'analytics/ambassadorScore',
        method: 'GET',
        queries: queryParams,
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null)
        console.error('Error fetching users:', errorData)
      }
      return response.json()
    },
    keepPreviousData: true,
  })
}

export function useGetUserAmbassadorScore(quickbloxUserId: number, params: QueryParams) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `analytics/ambassadorScore/${quickbloxUserId}/scores`,
        method: 'GET',
        queries: queryParams,
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null)
        console.error('Error fetching users:', errorData)
      }
      return response.json()
    },
    keepPreviousData: true,
  })
}

export function useGetDailyActiveUsers(params: QueryParams) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'analytics/kpi/db/dau/users',
        method: 'GET',
        queries: queryParams,
      })
      if (!response.ok) {
        throw new Error('Error fetching daily active users:');
      }
      return response.json();
    },
    keepPreviousData: true,
  });
}

export function useGetSingleUser(userId: number) {
  return useQuery({
    queryKey: [QUERY_KEYS.USER, userId],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `users/${userId}`,
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Error fetching user');
      }
      return response.json();
    },
  });
}

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: number) => fetchAdmin({ path: `users/${userId}`, method: 'DELETE' }),
    onSuccess: (_, userId) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.removeQueries([QUERY_KEYS.USER, userId]);
    },
  });
}

export function useBanUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: number) => fetchAdmin({ path: `users/${userId}/ban`, method: 'PUT' }),
    onSuccess: (_, userId) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  });
}

export function useSuspendUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, disabled, reason }: { userId: number; disabled: boolean; reason?: string }) => fetchAdmin({
      path: `users/${userId}/suspend`,
      method: 'PUT',
      body: { disabled, reason },
    }),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  })
}

export function useSwitchStaffStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, isStaff }: { userId: number; isStaff: boolean }) => fetchAdmin({
      path: `users/${userId}`,
      method: 'PUT',
      body: { isStaff },
    }),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  });
}

export function useSwitchOfficialStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      isOfficial,
    }: {
      userId: number;
      isOfficial: boolean;
    }) => fetchAdmin({
      path: `users/${userId}`,
      method: 'PUT',
      body: { isOfficial },
    }),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  });
}

export function useSwitchAmbassadorStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      userId,
      isAmbassador,
    }: {
      userId: number;
      isAmbassador: boolean;
    }) => fetchAdmin({
      path: `users/${userId}`,
      method: 'PUT',
      body: { isAmbassador },
    }),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  })
}

export function useChangeUserRegion() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, region }: { userId: number; region: string }) => fetchAdmin({
      path: `users/${userId}`,
      method: 'PUT',
      body: { region },
    }),
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries([QUERY_KEYS.USERS]);
      queryClient.invalidateQueries([QUERY_KEYS.USER, userId]);
    },
  });
}
