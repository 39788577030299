import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from '../keys'
import { fetchAdmin } from '../../../services/fetch'
import { QueryParams } from '../params'

export function useGetDialogs(params: QueryParams) {
  const queryParams = {
    ...params,
  }
  return useQuery({
    queryKey: [QUERY_KEYS.DIALOGS, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'dialogs',
        method: 'GET',
        queries: queryParams,
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null)
        console.error('Error fetching dialogs:', errorData) // eslint-disable-line no-console
      }
      const data = response.json()
      return data
    },
    keepPreviousData: true,
  })
}

export function useGetDialogDetails(quickbloxDialogId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `dialogs/${quickbloxDialogId}`,
        method: 'GET',
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null)
        console.error('Error fetching dialog:', errorData) // eslint-disable-line no-console
        throw new Error('Error fetching dialog details')
      }
      const data = await response.json()
      return data
    },
  })
}

export function useGetMemberDialogs(quickbloxDialogId?: string, params?: QueryParams) {
  return useQuery({
    queryKey: [QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: `dialogs/${quickbloxDialogId}/members`,
        method: 'GET',
        queries: params,
      })
      if (!response.ok) {
        const errorData = await response.json().catch(() => null)
        console.error('Error fetching dialog member:', errorData) // eslint-disable-line no-console
        throw new Error('Error fetching dialog member')
      }
      const data = await response.json()
      return data
    },
  })
}

export function useBanDialog() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quickbloxDialogId?: string) => fetchAdmin({
      path: `dialogs/${quickbloxDialogId}/ban`,
      method: 'PUT',
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}

export function useDeleteDialog() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quickbloxDialogId?: string) => fetchAdmin({
      path: `dialogs/${quickbloxDialogId}`,
      method: 'DELETE',
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}

export function useUpdateDialog() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ quickbloxDialogId, updateData }: { quickbloxDialogId: string, updateData: { name?: string, description?: string, photo?: string | null }}) => fetchAdmin({
      path: `dialogs/group/${quickbloxDialogId}`,
      method: 'PUT',
      body: updateData,
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}

export function useSwitchFeatureDialog() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ quickbloxDialogId, featured }: { quickbloxDialogId: string, featured: boolean }) => fetchAdmin({
      path: `dialogs/featured/${quickbloxDialogId}`,
      method: 'PUT',
      body: ({ featured }),
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}

export function useDeleteParticipants() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ quickbloxDialogId, qbUserId }: { quickbloxDialogId: string, qbUserId: number }) => fetchAdmin({
      path: `dialogs/${quickbloxDialogId}/participants/${qbUserId}`,
      method: 'DELETE',
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}

export function useGetExplore(params: QueryParams) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.EXPLORE, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'dialogs/explore',
        method: 'GET',
        queries: queryParams,
      });
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Error fetching dialogs:', errorData) // eslint-disable-line no-console
      }
      const data = response.json()
      return data
    },
    keepPreviousData: true,
  });
}

export function useGetFeatured(params: QueryParams) {
  const queryParams = {
    ...params,
  };
  return useQuery({
    queryKey: [QUERY_KEYS.FEATURED, params],
    queryFn: async () => {
      const response = await fetchAdmin({
        path: 'dialogs/featured',
        method: 'GET',
        queries: queryParams,
      });
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Error fetching dialogs:', errorData) // eslint-disable-line no-console
      }
      const data = response.json()
      return data
    },
    keepPreviousData: true,
  })
}

export function useCanSendMessage() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quickbloxDialogId? : string) => fetchAdmin({
      path: `dialogs/${quickbloxDialogId}/canSendMessage`,
      method: 'PUT',
    }),
    onSuccess: (_, quickbloxDialogId) => {
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOGS_DETAILS, quickbloxDialogId])
      queryClient.invalidateQueries([QUERY_KEYS.DIALOG_MEMBER, quickbloxDialogId])
    },
  })
}
