import { useState } from 'react'
import { URLSearchParamsInit } from 'react-router-dom'
import { useBaseTableSearchParams } from '../../hooks/useBaseTableSearchParams'

export function useFeaturedTableSearchParams(defaultInit?: URLSearchParamsInit) {
  const [showRegionSelect, setShowRegionSelect] = useState('')

  const baseTableParams = useBaseTableSearchParams({
    defaultInit,
    extraParams: {
      region: showRegionSelect,
    },
  })

  const queryParams = {
    ...baseTableParams.queryParams,
    region: showRegionSelect,
  }

  return {
    ...baseTableParams,
    showRegionSelect,
    setShowRegionSelect,
    queryParams,
    clearFilters: () => {
      baseTableParams.clearFilters()
      setShowRegionSelect('All')
    },
  };
}
