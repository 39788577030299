import { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { fetchData, generateColumnVisibilityModel } from './KpiDB'
import { initFormData, initColumns, columnGroupingModel } from '../../constants/kpiConstants'
import CustomToolbar from './CustomToolBar'

interface FormData {
  from: moment.Moment | null
  to: moment.Moment | null
  timezone: string
  storeCountryCode: string
  daysAfter: number | null
}

type UpdateFormData = Partial<FormData> & {
  columnVisibility?: Record<string, boolean>
}

export function SearchForm(props: { updateStatus: (obj: object) => void }) {
  const { updateStatus } = props
  const [formData, setFormData] = useState<FormData>(initFormData)
  const [isSubmitRequired, setIsSubmitRequired] = useState(false)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [columnVisibilityState, setColumnVisibilityState] = useState(() => {
    const savedModel = localStorage.getItem('columnVisibilityModel')
    return savedModel ? JSON.parse(savedModel) : generateColumnVisibilityModel()
  })
  const navigate = useNavigate()

  const updateFormData = (newFormData: UpdateFormData) => {
    const requiredKeys = ['from', 'to', 'storeCountryCode', 'timezone', 'daysAfter']
    if (requiredKeys.some((key) => key in newFormData)) {
      setIsSubmitRequired(true)
    }
    setFormData((old) => ({ ...old, ...newFormData }))
    if (newFormData.columnVisibility) {
      const updatedVisibility = {
        ...columnVisibilityState,
        ...newFormData.columnVisibility,
      }

      setColumnVisibilityState(updatedVisibility)
      localStorage.setItem('columnVisibilityModel', JSON.stringify(updatedVisibility))

      updateStatus({ columnVisibility: updatedVisibility })
      return
    }
    setIsDataFetched(false)
  }

  const fetchAndUpdateData = useCallback(async () => {
    if (isDataFetched || isLoading) return
    setIsLoading(true)
    updateStatus({ loading: true })

    try {
      const { columns, rows } = await fetchData({ formData, navigate })

      updateStatus({
        columns,
        rows,
        loading: false,
      });

      setIsDataFetched(true)
    } catch (error) {
      console.error(error) // eslint-disable-line no-console
    } finally {
      setIsLoading(false)
    }
  }, [formData, updateStatus, isDataFetched, isLoading, navigate])

  const handleSubmit = () => {
    setIsSubmitRequired(false)
    fetchAndUpdateData()
  }

  useEffect(() => {
    if (
      formData.from
      && formData.to
      && formData.storeCountryCode
      && formData.timezone
      && formData.daysAfter !== null
      && !isDataFetched
      && !isSubmitRequired
    ) {
      const delayFetch = setTimeout(() => {
        fetchAndUpdateData()
      }, 500)

      return () => clearTimeout(delayFetch)
    }

    return undefined
  }, [formData, fetchAndUpdateData, isDataFetched, isSubmitRequired])

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box padding={1}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="From"
              value={formData.from}
              onChange={(value) => updateFormData({ from: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />
            <DatePicker
              label="To"
              value={formData.to}
              onChange={(value) => updateFormData({ to: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />
            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="kpi-country-select-label">Country</InputLabel>
              <Select
                labelId="kpi-country-select-label"
                id="kpi-country-select"
                value={formData.storeCountryCode}
                label="Country"
                onChange={(event) => updateFormData({ storeCountryCode: event.target.value })}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                <MenuItem value="USA_CAN">USA & CAN</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="CAN">CAN</MenuItem>
                <MenuItem value="JPN">JPN</MenuItem>
                <MenuItem value="Others">OTHERS</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-timezone-select-label">Timezone</InputLabel>
              <Select
                labelId="kpi-timezone-select-label"
                id="kpi-timezone-select"
                value={formData.timezone}
                label="Timezone"
                onChange={(event) => updateFormData({ timezone: event.target.value })}
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="America/Los_Angeles">PT</MenuItem>
                <MenuItem value="Asia/Tokyo">JST</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-daysAfter-select-label">Days After</InputLabel>
              <Select
                labelId="kpi-daysAfter-select-label"
                id="kpi-daysAfter-select"
                value={formData.daysAfter}
                label="Days After"
                onChange={(event) => updateFormData({ daysAfter: Number(event.target.value) })}
              >
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={14}>14</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, align: 'center' }}>
              {isSubmitRequired && (
                <Button variant="outlined" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </FormControl>
          </Stack>
          <Box marginTop={1} marginBottom={2}>
            <CustomToolbar initColumns={initColumns} columnGroupingModel={columnGroupingModel} updateFormData={updateFormData} currentVisibility={columnVisibilityState} />
          </Box>
        </Box>
      </LocalizationProvider>
    </>
  )
}
