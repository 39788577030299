import {
  MaterialReactTable,
  type MRT_ColumnDef as MrtColumnDef,
} from 'material-react-table'
import { useMemo } from 'react'
import { DateCell } from '../../app/Timezone'
import { useGetAllUserAmbassador } from '../../../api/query/users/users'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { useBaseTableSearchParams } from '../hooks/useBaseTableSearchParams'
import { SCORE_BOARD } from '../../../constants'

type User = {
  quickbloxUserId: string
  userName: string
  fullName: string
  phone: string
  region: string
  reporter: string
  reported: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  userType?: string | null
  isOfficial?: boolean
  isStaff?: boolean
  isAmbassador?: boolean
  isDebug?: boolean
}

function AmbassadorUsers() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
  } = useBaseTableSearchParams()

  const columns: MrtColumnDef<User>[] = [
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: 150,
    },
    {
      accessorKey: 'userName',
      header: 'Username',
      size: 150,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 150,
    },
    {
      accessorKey: 'totalScore',
      header: 'Score',
      size: 150,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 250,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
  ]

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetAllUserAmbassador(queryParams)

  const getColumnFlexProps = (width: string) => ({
    muiTableHeadCellProps: { sx: { minWidth: width, width } },
    muiTableBodyCellProps: { sx: { minWidth: width, width } },
  })

  const criteriaCols = useMemo(() => [
    { accessorKey: 'activity', header: 'Activity', ...getColumnFlexProps('60%') },
    { accessorKey: 'points', header: 'Weight (Points per Action)', ...getColumnFlexProps('25%') },
    { accessorKey: 'daily_limit', header: 'Limit/Day', ...getColumnFlexProps('15%') },
  ], [])

  const criteriaRows = useMemo(() => Object.entries(SCORE_BOARD).map(([, { activity, points, dailyLimit }]) => ({
    activity,
    points,
    daily_limit: dailyLimit ?? '-',
  })), [])

  return (
    <>
      <div style={{ display: 'flex', gap: '30px', alignItems: 'start' }}>
        <div style={{ flex: '1' }}>
          <h3>Ambassador Users</h3>
          <MrtBaseDataGrid<User>
            columns={columns}
            data={data?.rows?.map((row: { quickbloxUserId: number }) => ({ ...row, id: row.quickbloxUserId })) || []}
            totalRows={data?.totalRows ?? 0}
            isLoading={isLoading}
            isError={isError}
            onRefetch={refetch}
            onClearFilters={clearFilters}
            onColumnFiltersChange={setColumnFilters}
            pagination={pagination}
            setPagination={setPagination}
            setSorting={setSorting}
            sorting={sorting}
            defaultPath="/analytics/ambassadorUsers"
          />
        </div>

        <div style={{ flex: '1' }}>
          <h3>Scoring Criteria</h3>
          <MaterialReactTable
            columns={criteriaCols}
            data={criteriaRows}
            enableSorting={false}
            enableColumnActions={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            muiTableProps={{
              sx: {
                borderCollapse: 'collapse',
                '& td, & th': {
                  border: '1px solid rgba(224, 224, 224, 1)',
                },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AmbassadorUsers
