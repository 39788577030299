import React, { ReactNode } from 'react'
import BugReportIcon from '@mui/icons-material/BugReport';
import { USERSTATUS } from '../../../../constants';
import { User } from '../../user/types';

function UserStatus({ statuses }: { statuses: string[] }) {
  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      {USERSTATUS.filter((status) => statuses.includes(status.value)).map(
        (status) => (
          <div
            key={status.value}
            style={{ display: 'flex', alignItems: 'center', margin: 'auto' }}
          >
            {status.badge && (
              <img
                src={status.badge}
                alt={status.label}
                style={{ width: '20px' }}
              />
            )}
            {status.value === 'isDebug' && <BugReportIcon color="error" />}
          </div>
        ),
      )}
    </div>
  );
}

export const getUserStatus = (user: User): ReactNode => {
  const statuses: string[] = [];
  if (user.isOfficial) statuses.push('isOfficial');
  if (user.isStaff) statuses.push('isStaff');
  if (user.isAmbassador) statuses.push('isAmbassador');
  if (user.isDebug) statuses.push('isDebug');

  return <UserStatus statuses={statuses.length ? statuses : ['regular']} />;
};

export const getUserStatusText = (user: User): string => {
  const element = getUserStatus(user)

  if (!React.isValidElement(element)) return 'Regular'

  const statuses = (element.props as { statuses?: string[] }).statuses || ['regular']

  return USERSTATUS
    .filter(({ value }) => statuses.includes(value))
    .map(({ label }) => label)
    .join(', ') || 'Regular'
}
