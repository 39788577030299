import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Box, IconButton, TextField, Typography,
} from '@mui/material'
import {
  GridCallbackDetails, GridCellParams, GridColumns, GridFilterModel, GridRowModesModel, GridRowParams, GridSortItem, GridSortModel,
  MuiBaseEvent, DataGrid as MuiDataGrid, MuiEvent, getGridDateOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators,
} from '@mui/x-data-grid'
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import moment from 'moment'
import eventBus from '../../services/eventBus'
import { fetchAdmin } from '../../services/fetch'
import { GridApiUrlQueries, UrlQueries } from '../../services/urlBuilder'
import { DateText, TimezoneContext } from '../app/Timezone'
import { QuickSearchToolbar } from './QuickSearchToolBar'
import { EVENTMOMENT, MAINTENANCE_STATUS } from '../../constants'
import { styleObj } from '../../styles/rowStyle'
import { convertToTimezone, exportToCSV } from '../v2/common/MrtBaseDataGrid'

interface Props {
  show: boolean
  refresh: boolean
  path: string
  columns: GridColumns
  autoHeight?: boolean
  showDeletedCheckbox?: boolean
  showResolvedCheckbox?: boolean
  showTimeZoneSelect?: boolean
  searchColumns?: Array<{ label: string, field: string }>
  rowHeight?: number
  setCustomRowClassName?: (params: GridRowParams) => string // NOTE: for row color
  setCustomRowDescriptions?: Array<{
    rowClassName: string
    description: string
  }>
  onRowDoubleClick?: (params: GridRowParams) => void
  initialSortItem?: GridSortItem
  sortingMode?: 'client' | 'server'
  filterMode?: 'client' | 'server'
  disableToolbar?: boolean
  toolbarComponent?: React.JSXElementConstructor<any>
  initToolbarQueries?: UrlQueries
  showContainDateRange?: boolean
  editMode?: 'cell' | 'row'
  experimentalFeatures?: ExperimentalFeatures
  onRowEditStart?: (params: GridRowParams, event: MuiEvent<React.KeyboardEvent | React.MouseEvent>) => void
  onRowEditStop?: (params: GridRowParams, event: MuiEvent<MuiBaseEvent>) => void
  rowModesModel?: GridRowModesModel
  processRowUpdate?: (newRow: Object, oldRow: Object) => Promise<Object> | Object
  onCellKeyDown?: (params: GridCellParams, event: MuiEvent<React.KeyboardEvent>, details: GridCallbackDetails) => void
  showExport?: boolean
}

type FormData = {
  from: moment.Moment | null,
  to: moment.Moment | null,
  timezone: string | null,
}

type ExperimentalFeatures = {
  columnGrouping?: boolean,
  newEditingApi?: boolean,
  preventCommitWhileValidating?: boolean,
  warnIfFocusStateIsNotSynced?: boolean
}

function DataGrid(props: Props) {
  const {
    autoHeight,
    rowHeight,
    onRowDoubleClick,
    sortingMode,
    filterMode,
    initialSortItem,
    searchColumns,
    showDeletedCheckbox,
    showResolvedCheckbox,
    setCustomRowClassName,
    setCustomRowDescriptions,
    showTimeZoneSelect,
    disableToolbar,
    toolbarComponent,
    initToolbarQueries,
    showContainDateRange,
    experimentalFeatures,
    editMode,
    onRowEditStart,
    onRowEditStop,
    rowModesModel,
    processRowUpdate,
    onCellKeyDown,
    showExport,
  } = props

  const {
    show, refresh, path, columns,
  } = props
  const [pageState, setPageState] = useState({
    isLoading: false,
    rows: [],
    totalRows: 0,
    page: 0,
    pageSize: 25,
    sortModel: [initialSortItem ?? { field: 'createdAt', sort: 'desc' }] as GridSortModel,
    filterModel: { items: [] } as GridFilterModel,
  })

  // Manage the state of the date range
  const [formData, setFormData] = useState<FormData>({
    from: moment().subtract(7, 'day').startOf('day'),
    to: moment().subtract(1, 'day').endOf('day'),
    timezone: null, // default timezeone if constant not stated any
  })

  const handleDateChange = (newDateRange: object) => {
    setFormData((prev) => ({ ...prev, ...newDateRange }));
  }

  const [searchColumn, setSearchColumn] = useState((searchColumns != null) ? searchColumns[0].field : 'id')
  const [showDeleted, setShowDeleted] = useState(true)
  const [showResolved, setShowResolved] = useState(false)
  const [toolbarQueries, setToolbarQueries] = useState(initToolbarQueries)

  function updatePageState(newState: object) {
    setPageState((old) => ({ ...old, ...newState }))
  }

  const fetchData = useCallback(async () => {
    if (sortingMode === 'client' && filterMode === 'client') {
      return
    }
    let rangeTimeFilter = {}
    if (showContainDateRange && formData.from && formData.to) {
      rangeTimeFilter = {
        rangeTimeOp: 'between',
        rangeTimeField: 'createdAt',
        rangeTimeValues: `${formData.from.format('yyyy-MM-DD HH:mm:ss')},${formData.to.format('yyyy-MM-DD HH:mm:ss')}`,
        rangeTimeZone: formData.timezone || EVENTMOMENT[0].timezone,
      }
    }
    const queries: GridApiUrlQueries = {
      limit: pageState.pageSize,
      offset: pageState.page * pageState.pageSize,
      showDeleted,
      showResolved,
      ...rangeTimeFilter,
      ...toolbarQueries,
    }

    if (pageState.sortModel.length > 0) {
      // set default sort for status sort
      if (pageState.sortModel[0].field === 'status') {
        queries.sortField = 'startTime'
        queries.dir = 'desc'
      } else {
        queries.sortField = pageState.sortModel[0].field
        queries.dir = pageState.sortModel[0].sort
      }
    }

    if (pageState.filterModel.items.length > 0 && pageState.filterModel.items[0].value !== undefined) {
      const statusFilter = pageState.filterModel.items.find((item) => item.columnField === 'status')
      if (statusFilter) {
        queries.filterField = 'status'
        queries.filterOp = 'in'
        queries.filterValue = statusFilter.value
      } else {
        queries.filterField = pageState.filterModel.items[0].columnField
        queries.filterOp = pageState.filterModel.items[0].operatorValue
        queries.filterValue = pageState.filterModel.items[0].value
      }
    }

    // NOTE: search function
    if ((pageState.filterModel.quickFilterValues != null) && pageState.filterModel.quickFilterValues.length > 0) {
      queries.searchOp = 'contains'
      queries.searchField = searchColumn
      queries.searchValues = pageState.filterModel.quickFilterValues.join(',')
    }

    try {
      updatePageState({ isLoading: true })

      const response = await fetchAdmin({
        path,
        method: 'GET',
        queries,
      })
      const json = await response.json()

      // add status to the rows on maintenance for filtering & sorting
      if (path === 'maintenances') {
        const rowsWithStatus = json.rows.map((row: { startTime: string; endTime: string; deletedAt: string }) => ({
          ...row,
          status: getStatusMaintenance(row.startTime, row.endTime, row.deletedAt),
        }))

        const statusFilter = pageState.filterModel.items.find((item) => item.columnField === 'status')
        let newRows = statusFilter && statusFilter.value !== undefined
          ? rowsWithStatus.filter((row: { status: string }) => row.status === statusFilter.value)
          : rowsWithStatus

        if (pageState.sortModel.length > 0 && pageState.sortModel[0].field === 'status') {
          const sortField = pageState.sortModel[0].field
          const sortDirection = pageState.sortModel[0].sort

          newRows = newRows.sort((a: { [x: string]: string }, b: { [x: string]: string }) => {
            let valueA = a[sortField]
            let valueB = b[sortField]

            if (sortField === 'status') {
              valueA = valueA.toLowerCase()
              valueB = valueB.toLowerCase()
            }

            if (sortDirection === 'asc') {
              if (valueA < valueB) return -1
              if (valueA > valueB) return 1
            } else {
              if (valueA > valueB) return -1
              if (valueA < valueB) return 1
            }
            return 0
          })
        }

        updatePageState({ rows: newRows, totalRows: json.totalRows, isLoading: false })
      } else {
        updatePageState({ rows: json.rows, totalRows: json.totalRows, isLoading: false })
      }
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }, [showContainDateRange, formData, pageState.page, pageState.pageSize, pageState.sortModel, pageState.filterModel, searchColumn, path, showDeleted, showResolved, sortingMode, filterMode, toolbarQueries])

  useEffect(() => {
    fetchData()
  }, [fetchData, refresh])

  const { timezone } = useContext(TimezoneContext)

  const handleExportData = () => {
    exportToCSV(pageState.rows, columns, path, timezone)
  }

  return show
    ? (
      <DataGridBox>
        <MuiDataGrid
          columns={columns}
          rows={pageState.rows}
          rowCount={pageState.totalRows}
          rowHeight={rowHeight ?? 40}
          autoHeight={autoHeight ?? false}
          loading={pageState.isLoading}
          page={pageState.page}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(page) => updatePageState({ page })}
          onPageSizeChange={(pageSize) => updatePageState({ pageSize })}
          sortingMode={sortingMode ?? 'server'}
          sortModel={pageState.sortModel}
          onSortModelChange={(sortModel) => updatePageState({ sortModel })}
          filterMode={filterMode ?? 'server'}
          filterModel={pageState.filterModel}
          onFilterModelChange={(filterModel) => updatePageState({ filterModel })}
          onRowDoubleClick={onRowDoubleClick}
          components={disableToolbar ? undefined : { Toolbar: toolbarComponent ?? QuickSearchToolbar }} // NOTE: search function
          componentsProps={{ // NOTE: search function
            toolbar: {
              showDeleted,
              setShowDeleted,
              showResolvedCheckbox: showResolvedCheckbox ?? false,
              showDeletedCheckbox: showDeletedCheckbox ?? false,
              showResolved,
              setShowResolved,
              setSearchColumn,
              autoCompleteOptions: searchColumns ?? [{ label: 'ID', field: 'id' }],
              customRowDescriptions: setCustomRowDescriptions,
              showTimeZoneSelect,
              setToolbarQueries,
              toolbarQueries,
              showContainDateRange: showContainDateRange ?? false,
              formData, // Pass the current form data (date range)
              onDateChange: handleDateChange, // Pass handler to update date range
              showExportName: showExport ? path : null,
              onExportData: handleExportData,
              exportDataLength: pageState.rows.length ?? 0,
            },
          }}
          getRowClassName={setCustomRowClassName}
          experimentalFeatures={experimentalFeatures}
          editMode={editMode}
          processRowUpdate={processRowUpdate}
          onRowEditStart={onRowEditStart}
          onRowEditStop={onRowEditStop}
          rowModesModel={rowModesModel}
          onCellKeyDown={onCellKeyDown}
        />
      </DataGridBox>
    )
    : null
}

export default DataGrid

export function buildDetailsButton<T>({
  event,
  callback,
}: {
  event?: string
  callback?: (data: T) => void
}): React.FC<{ data: T }> {
  const button: React.FC<{ data: T }> = (props) => {
    const { data } = props

    function handleClick() {
      if (event) {
        eventBus.dispatch(event, data)
      } else if (callback != null) {
        callback(data)
      }
    }

    return (
      <IconButton onClick={handleClick}>
        <NavigateNextIcon />
      </IconButton>
    )
  }

  return button
}

export const filterStringOps = getGridStringOperators().filter(
  (op) => op.value !== 'isAnyOf',
)

export const filterNumericOps = getGridNumericOperators().filter(
  (op) => op.value === '=',
)

export const filterDateTimeOps = getGridDateOperators(true).filter(
  (op) => op.value === 'before' || op.value === 'after',
)

export const fliterSingleSelectOps = getGridSingleSelectOperators().filter(
  (op) => op.value === 'is',
)

export function DateCell(props: { date: string }) {
  const { date } = props

  return <Typography variant="body2"><DateText date={date} /></Typography>
}

interface DataGridBoxProps {
  children?: React.ReactNode
}

function DataGridBox(props: DataGridBoxProps) {
  const { children } = props

  return (
    <Box // custom role color
      sx={{
        height: '100%',
        width: '100%',
        '& .row-color-info': styleObj.info,
        '& .row-color-error': styleObj.error,
        '& .row-color-disabled': styleObj.disabled,
        '& .row-color-completed': styleObj.completed,
      }}
    >
      {children}
    </Box>
  )
}

export const getStatusMaintenance = (startTime: string, endTime: string, deletedAt: string | null): string => {
  const now = Date.now()
  const start = new Date(startTime).getTime()
  const end = new Date(endTime).getTime()
  if (deletedAt !== null) return MAINTENANCE_STATUS.DELETED
  if (now < start) return MAINTENANCE_STATUS.UPCOMING
  if (now <= end) return MAINTENANCE_STATUS.ONGOING
  return MAINTENANCE_STATUS.COMPLETED
}
