import React, { useState, useCallback } from 'react';

interface ConfirmationModalState {
  open: boolean;
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: (value?: string) => void
  content?: React.ReactNode
  form?: boolean
}

interface UseConfirmationModalReturn {
  modalState: ConfirmationModalState;
  handleOpen: (config: Omit<ConfirmationModalState, 'open'>) => void;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const useConfirmationModal = (): UseConfirmationModalReturn => {
  const [modalState, setModalState] = useState<ConfirmationModalState>({
    open: false,
    title: '',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    form: false,
  });

  const handleOpen = useCallback(
    (config: Omit<ConfirmationModalState, 'open'>) => {
      setModalState({ ...config, open: true });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setModalState((prev) => ({ ...prev, open: false }));
  }, []);

  const handleConfirm = useCallback(
    (value?: string) => {
      if (modalState.onConfirm) {
        modalState.onConfirm(value);
      }
      handleClose();
    },
    [modalState, handleClose],
  )

  return {
    modalState,
    handleOpen,
    handleClose,
    handleConfirm,
  };
};
