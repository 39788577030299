import { useEffect } from 'react'
import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table'
import {
  Checkbox, FormControlLabel, FormGroup,
} from '@mui/material'
import { DateCell } from '../../app/Timezone'
import MrtBaseDataGrid from '../common/MrtBaseDataGrid'
import { styleObj } from '../../../styles/rowStyle'
import CustomDateFilter from '../common/date/CustomDateFilter'
import { DIALOG_TYPES } from '../../../constants'
import { useGetDialogs } from '../../../api/query/dialogs/dialogs'
import CustomTypeFilter from '../common/dialogs/CustomTypeFilter'
import { DialogGrid } from './types'
import { useBaseTableSearchParams } from '../hooks/useBaseTableSearchParams'

function Dialogs() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    filter,
    setFilter,
    queryParams,
    clearFilters,
    showDeleted,
    setShowDeleted,
  } = useBaseTableSearchParams()

  const columns: MrtColumnDef<DialogGrid>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 250,
    },
    {
      accessorKey: 'quickbloxDialogId',
      header: 'Quickblox Dialog ID',
      size: 230,
      Cell: ({ row }) => row.original.id,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 150,
      enableSorting: false,
      Cell: ({ row }) => DIALOG_TYPES[row.original.type as keyof typeof DIALOG_TYPES] || null,
      Filter: () => CustomTypeFilter({
        field: 'type',
        filter: {
          filterOp: 'equals',
          filterValue: filter.filterValue,
        },
        setFilter,
      }),
    },
    {
      accessorKey: 'count_participant',
      header: 'Participants',
      size: 150,
      enableSorting: true,
      Cell: ({ row }) => row.original.count_participant,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter, setFilter }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter, setFilter }),
    },
    {
      accessorKey: 'deletedAt',
      header: 'Deleted At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'deletedAt', filter, setFilter }),
    },
  ]

  const {
    data,
    isError,
    isLoading,
    refetch,
  } = useGetDialogs(queryParams)

  useEffect(() => {
    refetch()
  }, [queryParams, refetch])

  const customStyle = (row: MrtRow<DialogGrid>) => ({
    ...(row.original.deletedAt ? styleObj.error : {}),
  })

  const renderShowDeleted = () => (
    <FormGroup sx={{ ml: 1 }}>
      <FormControlLabel
        control={(
          <Checkbox
            color="error"
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
          />
        )}
        label="Include Deleted"
      />
    </FormGroup>
  )

  return (
    <MrtBaseDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      defaultPath="/dialogs"
      renderTopToolbarCustomContent={renderShowDeleted}
      showExport
    />
  )
}

export default Dialogs
