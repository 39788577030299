import { Delete } from '@mui/icons-material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import {
  Button, Card, CardActions, CardContent, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import eventBus from '../../services/eventBus'
import { fetchAdmin } from '../../services/fetch'
import ConfirmModal from '../common/ConfirmModal'
import WidgetTagsAuth, { expectedWidgetDeleteReportTag } from '../common/WidgetTagsAuth'
import DeleteMessageReportModal from './DeleteMessageReportModal'
import { EVENT_GO_BACK, MessageReportInfo } from './MessageReports'
import QuickbloxMessageDetailsCard from './QuickbloxMessageDetailsCard'
import ResolveAllMessageReportsModal from './ResolveAllMessageReportsModal'
import ResolveMessageReportModal from './ResolveMessageReportModal'
import UserProfileLink from '../user/UserProfileLink'
import MessageExtendDetailsCard from './MessageExtendDetailsCard'

interface Props {
  reportInfo?: MessageReportInfo
}

interface Status {
  showReporterUser: boolean
  showReportedUser: boolean
  showResolveModal: boolean
  showResolveAllModal: boolean
  showDeleteReportModal: boolean
  showDeleteMessageModal: boolean
}

function MessageReportDetails(props: Props) {
  const { reportInfo } = props
  const { identifier } = useParams()
  const [status, setStatus] = useState({
    showReporterUser: false,
    showReportedUser: false,
    showResolveModal: false,
    showResolveAllModal: false,
    showDeleteReportModal: false,
    showDeleteMessageModal: false,
  } as Status)
  const [details, setDetails] = useState<MessageReportInfo>()
  const navigate = useNavigate()

  function updateStatus(newStatus: {}) {
    setStatus((old) => ({
      ...old,
      ...newStatus,
    }))
  }

  function onExit() {
    if (reportInfo) {
      eventBus.dispatch(EVENT_GO_BACK)
    } else {
      navigate('/reports/messages')
    }
  }

  useEffect(() => {
    if (reportInfo) {
      setDetails(reportInfo)
    } else {
      fetchAdmin({
        path: `message-reports/${identifier}`,
        method: 'GET',
      })
        .then((res) => res.json())
        .then((json) => setDetails(json))
    }
  }, [reportInfo, identifier])

  useEffect(() => {
    if ((status.showReporterUser || status.showReportedUser) && details) {
      navigate(`/users/${status.showReporterUser ? details.reporterQuickbloxUserId : details.reportedQuickbloxUserId}`)
    }
  }, [status, details, navigate])

  if (details === undefined) {
    return null
  }

  return (
    <ReportDetails
      reportInfo={details}
      status={status}
      updateStatus={updateStatus}
      onExit={onExit}
    />
  )
}

interface ReportDetailsProps extends Props {
  reportInfo: MessageReportInfo
  status: Status
  updateStatus: (newStatus: {}) => void
  onExit: () => void
}

function ReportDetails(props: ReportDetailsProps) {
  const {
    reportInfo,
    status,
    updateStatus,
    onExit,
  } = props

  const [disabledDeleteButton, setDisabledDeleteButton] = useState(true)

  async function deleteMessage() {
    await fetchAdmin({
      path: `message-reports/${reportInfo.id}/message`,
      method: 'DELETE',
    })

    return {
      callback: () => onExit(),
    }
  }

  return (
    <>
      <Button
        startIcon={<NavigateBeforeIcon />}
        onClick={() => onExit()}
      >
        Reports
      </Button>

      <Stack direction="row">
        <Typography variant="h3" gutterBottom>
          {`Report #${reportInfo.id}`}
        </Typography>
      </Stack>
      <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => updateStatus({ showDeleteReportModal: true })}
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            disabled={disabledDeleteButton}
          >
            Delete
          </Button>
        </Stack>
      </WidgetTagsAuth>
      <Grid container spacing={2}>
        <Grid container item spacing={2} lg={8} direction="column">
          <Grid item>
            <ReportDetailsCard
              reportInfo={reportInfo}
              handleResolve={() => updateStatus({ showResolveModal: true })}
              handleResolveAll={() => updateStatus({ showResolveAllModal: true })}
              showDeleteMessageModal={() => updateStatus({ showDeleteMessageModal: !status.showDeleteMessageModal })}
            />
          </Grid>

          <Grid item>
            <QuickbloxMessageDetailsCard
              userId={reportInfo.reportedQuickbloxUserId}
              dialogId={reportInfo.quickbloxDialogId}
              messageId={reportInfo.quickbloxMessageId}
              setDisabledDeleteButton={setDisabledDeleteButton}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={2} lg={4} direction="column">
          <Grid item>
            <UserCard onClickUserDetails={() => updateStatus({ showReportedUser: true })} isReporter={false} />
          </Grid>
          <Grid item>
            <UserCard onClickUserDetails={() => updateStatus({ showReporterUser: true })} isReporter />
          </Grid>
          <Grid item>
            <MessageExtendDetailsCard userId={reportInfo.reportedQuickbloxUserId} dialogId={reportInfo.quickbloxDialogId} messageId={reportInfo.quickbloxMessageId} message={reportInfo.quickbloxMessage} />
          </Grid>
        </Grid>
      </Grid>

      <ResolveMessageReportModal
        reportId={reportInfo.id}
        open={status.showResolveModal}
        handleClose={(actionTriggered: boolean) => {
          updateStatus({ showResolveModal: false })
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ResolveAllMessageReportsModal
        quickbloxDialogId={reportInfo.quickbloxDialogId}
        quickbloxMessageId={reportInfo.quickbloxMessageId}
        open={status.showResolveAllModal}
        handleClose={(actionTriggered: boolean) => {
          updateStatus({ showResolveAllModal: false })
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <DeleteMessageReportModal
        reportId={reportInfo.id}
        open={status.showDeleteReportModal}
        handleClose={(actionTriggered: boolean) => {
          updateStatus({ showDeleteReportModal: false })
          if (actionTriggered) {
            onExit()
          }
        }}
      />

      <ConfirmModal
        openTrigger={status.showDeleteMessageModal}
        title={`Delete QB Message #${reportInfo.quickbloxMessageId}?`}
        message="Once removed, you can&apos;t undo this operations."
        labelAccept="remove"
        onAccept={deleteMessage}
      />
    </>
  )
}

interface ReportDetailsCardProps extends Props {
  reportInfo: MessageReportInfo
  handleResolve: () => void
  handleResolveAll: () => void
  showDeleteMessageModal: () => void
}

function ReportDetailsCard(props: ReportDetailsCardProps) {
  const {
    reportInfo, handleResolve, handleResolveAll, showDeleteMessageModal,
  } = props
  const createdAt = moment(new Date(reportInfo.createdAt)).format('MMMM Do YYYY, h:mm:ss A')
  const updatedAt = moment(new Date(reportInfo.updatedAt)).format('MMMM Do YYYY, h:mm:ss A')
  const resolvedAt = reportInfo.resolvedAt ? moment(new Date(reportInfo.resolvedAt)).format('MMMM Do YYYY, h:mm:ss A') : null

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Report Message
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {reportInfo.details ? reportInfo.details : 'No message.'}
        </Typography>
      </CardContent>

      <CardContent>
        <Typography variant="h4" gutterBottom>
          Reported QB Message
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {reportInfo.quickbloxMessage}
        </Typography>
      </CardContent>

      <CardContent>
        <Typography variant="h4" gutterBottom>
          Report Details
        </Typography>

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reporter QB User ID
                </TableCell>
                <TableCell>
                  <UserProfileLink userId={reportInfo.reporterQuickbloxUserId} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reported QB User ID
                </TableCell>
                <TableCell>
                  <UserProfileLink userId={reportInfo.reportedQuickbloxUserId} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reported QB Dialog ID
                </TableCell>
                <TableCell>
                  {reportInfo.quickbloxDialogId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Reported QB Message ID
                </TableCell>
                <TableCell>
                  {reportInfo.quickbloxMessageId}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Category
                </TableCell>
                <TableCell>
                  {reportInfo.category}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Created At
                </TableCell>
                <TableCell>
                  {createdAt}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Updated At
                </TableCell>
                <TableCell>
                  {updatedAt}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Resolved At
                </TableCell>
                <TableCell>
                  {resolvedAt || 'Not Yet Resolved'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <WidgetTagsAuth expectedWidgetTag={expectedWidgetDeleteReportTag}>
        <CardActions>
          <Button variant="outlined" disabled={Boolean(reportInfo.resolvedAt)} onClick={handleResolve}>Resolve</Button>
          <Button variant="outlined" onClick={handleResolveAll}>Resolve All</Button>
          <Button variant="outlined" onClick={showDeleteMessageModal}>Delete QB Message</Button>
        </CardActions>
      </WidgetTagsAuth>
    </Card>
  )
}

interface UserCardProps {
  onClickUserDetails: () => void
  isReporter: boolean
}

function UserCard(props: UserCardProps) {
  const { onClickUserDetails, isReporter } = props

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {isReporter ? 'Reporter' : 'Reported'}
          {' '}
          User Details
        </Typography>
        <Typography variant="body2">
          Click here to view user details.
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={onClickUserDetails}>User Details</Button>
      </CardActions>
    </Card>
  )
}

export default MessageReportDetails
