import { NavigateBefore } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchAdmin } from '../../services/fetch'
import { Tab, TabPanel, Tabs } from '../common/Tabs'
import UserDetails from '../v2/user/details/UserDetails'
import { GiftCodeInfo } from './GiftCodes'
import UserProfileLink from '../user/UserProfileLink'

function GiftCodeDetails() {
  const { id } = useParams()
  const [info, setInfo] = useState({} as GiftCodeInfo)
  const navigate = useNavigate()

  useEffect(() => {
    fetchAdmin({
      path: `campaign/gift-codes/${id}`,
      method: 'GET',
    })
      .then((res) => res.json())
      .then((giftCode) => setInfo(giftCode))
  }, [id])

  return (
    <>
      <Button
        startIcon={<NavigateBefore />}
        onClick={() => navigate('/campaign/giftCodes')}
      >
        GiftCodes
      </Button>

      {
        Object.keys(info).length !== 0
        && (
          <>
            <Tabs>
              <Tab label="gift code" />
              <Tab label="request user" />
            </Tabs>

            <TabPanel>
              <GiftCodeDetailsCard info={info} />
            </TabPanel>

            <TabPanel>
              <Box sx={{ width: '100%' }}>
                <UserDetails quickbloxUserIdByTab={info.quickbloxUserId} />
              </Box>
            </TabPanel>
          </>
        )
      }
    </>
  )
}

export default GiftCodeDetails

interface GiftCodeDetailsCardProp {
  info: GiftCodeInfo
}

function GiftCodeDetailsCard(props: GiftCodeDetailsCardProp) {
  const { info } = props
  const completions = info.completions.map((row) => {
    const tableRows = [
      {
        label: 'Table Name',
        value: row.tableName,
      },
      {
        label: 'Table ID',
        value: row.tableId,
      },
      {
        label: 'Amount',
        value: row.amount,
      },
    ]

    if (row.tableData) {
      const excludeKeys = ['id', 'createdAt', 'updatedAt', 'deletedAt']

      Object.entries(row.tableData).forEach(([key, value]) => {
        if (!excludeKeys.includes(key)) {
          tableRows.push({
            label: `${row.tableName}.${key}`,
            value,
          })
        }
      })
    }

    return {
      id: row.id,
      tableRows,
    }
  })

  return (
    <>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="h3" gutterBottom>
          {`GiftCode #${info.id}`}
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <TableBox
                title="Details"
                rows={[
                  {
                    label: 'QB User ID',
                    value: <UserProfileLink userId={info.quickbloxUserId} />,
                  },
                  {
                    label: 'Amount',
                    value: info.amount,
                  },
                  {
                    label: 'Gift Code',
                    value: info.giftCode,
                  },
                  {
                    label: 'Result',
                    value: info.result,
                  },
                  {
                    label: 'Created At',
                    value: info.createdAt,
                  },
                  {
                    label: 'Updated At',
                    value: info.updatedAt,
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <TableBox
                title="Campaign Entry"
                rows={[
                  {
                    label: 'Campaign ID',
                    value: info.entry?.campaignId,
                  },
                  {
                    label: 'QB User Id',
                    value: info.entry && <UserProfileLink userId={info.entry.quickbloxUserId} />,
                  },
                  {
                    label: 'Phone',
                    value: info.entry?.phone,
                  },
                  {
                    label: 'State',
                    value: info.entry?.state,
                  },
                  {
                    label: 'Created At',
                    value: info.entry?.createdAt,
                  },
                  {
                    label: 'Updated At',
                    value: info.entry?.updatedAt,
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {
        completions.map(
          (row) => (
            <Box mt={2} key={row.id}>
              <Card>
                <CardContent>
                  <TableBox
                    title={`Completion#${row.id}`}
                    rows={row.tableRows}
                  />
                </CardContent>
              </Card>
            </Box>
          ),
        )
      }
    </>
  )
}

interface TableBoxProps {
  title?: string
  rows: {
    label: string
    value?: string | number | ReactNode
  }[]
}

function TableBox(props: TableBoxProps) {
  const { title, rows } = props

  return (
    <TableContainer>
      {
        title
        && (
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
        )
      }

      <Table>
        <TableBody>
          {
            rows.map(
              (row) => (
                <TableRow key={row.label}>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {row.label}
                  </TableCell>
                  <TableCell>
                    {row.value}
                  </TableCell>
                </TableRow>
              ),
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
