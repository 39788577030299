import React from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useGetSingleUser } from '../../../../api/query/users/users'
import { UserDetailsCard } from './UserDetailsCard'
import { UserMomentsCard } from './UserMomentsCard'
import { UserDialogsCard } from './UserDialogsCard'
import { UserReportsCard } from './UserReportsCard'
import { UserFriendsCard } from './UserFriendsCard'
import { User } from '../types'
import Tabs, { type TabItem } from '../../common/Tabs'

function UserDetails({ quickbloxUserIdByTab } : { quickbloxUserIdByTab?: number }) {
  const { quickbloxUserId } = useParams()

  const userId = quickbloxUserIdByTab ?? parseInt(quickbloxUserId as string)

  const { data, isLoading, isError } = useGetSingleUser(userId)

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return <div>Error fetching user</div>
  }

  if (!data) {
    return <div>User not found</div>
  }

  const userData: User = {
    ...data.user,
    dialogsCount: data.dialogs.length,
  }

  const dialogsData = data.dialogs
  const reportsData = data.reports
  const friendsData = data.friends

  const tabs: TabItem[] = [
    {
      label: 'details',
      content: <UserDetailsCard user={userData} />,
    },
    {
      label: 'moments',
      content: <UserMomentsCard quickbloxUserId={userId} />,
    },
    {
      label: 'dialogs',
      content: (
        <UserDialogsCard dialogs={dialogsData} quickbloxUserId={userId} />
      ),
    },
    {
      label: 'reports',
      content: <UserReportsCard reports={reportsData} />,
    },
    {
      label: 'friends',
      content: <UserFriendsCard friends={friendsData} />,
    },
  ]

  return <Tabs tabs={tabs} defaultTab="details" />
}

export default UserDetails
