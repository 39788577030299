import React, { useState } from 'react'
import {
  CircularProgress, Grid, Pagination,
  Box,
  Stack,
} from '@mui/material';
import VideoCard from '../../../common/VideoCard';
import { buildMomentThumbnailUrl, buildMomentVideoUrl } from '../../../../services/urlBuilder';
import { UserMoments } from '../../moments/types';
import { useGetMomentsByUser } from '../../../../api/query/moments/moments';

const COLUMN_COUNT = 7;
const LIMIT = COLUMN_COUNT * 3;

export function UserMomentsCard({ quickbloxUserId }: { quickbloxUserId: number }) {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError } = useGetMomentsByUser(quickbloxUserId, {
    limit: LIMIT,
    offset: (page - 1) * LIMIT,
    sortField: 'id',
    dir: 'desc',
    showDeleted: true,
  });

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return <div>Error fetching moments</div>
  }

  if (!data || data.rows.length === 0) {
    return <div>This user has no moments yet!</div>
  }

  const momentsData: UserMoments[] = data.rows;

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Grid
        container
        spacing={1}
        padding={1}
        marginY={1}
        sx={{
          overflowY: 'auto',
        }}
      >
        {momentsData.map((moment) => (
          <Grid key={moment.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <VideoCard
              thumbnailUrl={buildMomentThumbnailUrl(moment.contents.top.thumbnailPublicId)}
              videoUrl={buildMomentVideoUrl(moment.contents.top.mediaPublicId)}
            />
          </Grid>
        ))}
      </Grid>
      <Stack>
        <Pagination
          page={page}
          count={Math.ceil(data.totalRows / LIMIT)}
          variant="outlined"
          shape="rounded"
          sx={{ margin: 'auto' }}
          onChange={handlePageChange}
        />
      </Stack>
    </Box>
  )
}
